import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  FiCheck,
  FiChevronLeft,
  FiEdit,
  FiPlusCircle,
  FiTrash,
  FiX,
} from 'react-icons/fi';
import { ImSpinner2 } from 'react-icons/im';
import { DayRange } from 'react-modern-calendar-datepicker';
import DatePicker from 'react-modern-calendar-datepicker';
import myCustomLocale from 'utils/calendarLocale';
import {
  Agent,
  CitiesWithEnterprises,
  ContactsProps,
  RequestCreateOrUpdateContact,
} from 'utils/InterFaces';
import { cpfValidate, removeSpecialCaracteres } from 'utils/numberUtils';
import { normalizeText } from 'utils/TextFormatter';
import AlertMessage from './Alert/AlertMessage';
import {
  ButtonCloseCollapse,
  ButtonXLS,
  Content,
  CreateContact,
  Header,
  Search,
  SearchContacts,
  SearchDate,
  TableContent,
  Tabs,
} from './styles';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

interface Props {
  children?: ReactNode;
  contactData: ContactsProps[];
  enterpriseData: CitiesWithEnterprises[];
  agents: Agent[];
  handleShowContactModal: () => void;
  callbackVerify: (cpfOrPhoneNumber: string) => Promise<AxiosResponse>;
  callbackCreate: (
    params: RequestCreateOrUpdateContact,
  ) => Promise<AxiosResponse>;
  callbackUpdate: (
    params: RequestCreateOrUpdateContact,
  ) => Promise<AxiosResponse>;
  callbackStatus: (id: number) => Promise<AxiosResponse>;
}

const ContactsTable = (props: Props) => {
  const [contacts, setContacts] = useState<ContactsProps[]>([]);
  const [contactsSearch, setContactsSearch] = useState<ContactsProps[]>([]);

  const [contactsDiscarded, setContactsDiscarded] = useState<ContactsProps[]>(
    [],
  );
  const [contactsDiscardedSearch, setContactsDiscardedSearch] = useState<
    ContactsProps[]
  >([]);

  const [contactsConversions, setContactsConversions] = useState<
    ContactsProps[]
  >([]);
  const [contactsConversionsSearch, setContactsConversionsSearch] = useState<
    ContactsProps[]
  >([]);

  const [listAgents, setListAgents] = useState<Agent[]>();
  const [showActive, setShowActive] = useState('active');
  const [waiting, setWaiting] = useState(false);
  const [waitingDiscarded, setWaitingDiscarded] = useState(0);
  const [waitingVerify, setWaintingVerify] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState<any>({});
  const [indexEdit, setIndexEdit] = useState<boolean | number>(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [rowEditing, setRowEditing] = useState<ContactsProps>({});
  const [rowCreating, setRowCreating] = useState<ContactsProps>({});
  const [enterproseSelect, setEnterpriseSelect] = useState<
    CitiesWithEnterprises[]
  >([]);
  const [errorMessage, setErrorMessage] = useState({
    isShow: false,
    message: '',
  });
  const [inputSearch, setInputSearch] = useState('');
  const [messageVerify, setMessageVerify] = useState({
    agent: '',
    message: '',
  });
  const [numberVerify, setNumberVerify] = useState('');
  const tableRef = useRef(null);
  const [dateFilter, setDateFilter] = useState<DayRange>({
    from: null,
    to: null,
  });

  const handleShowEditRow = (index: number) => {
    setIndexEdit(index);
    setRowEditing(contacts[index]);
  };

  const handleShowCreateRow = () => {
    setIsCreating(!isCreating);
  };

  const handleSaveNewRow = async () => {
    if (!isCreating) {
      return;
    }

    if (
      rowCreating.cpf &&
      rowCreating.cpf !== '' &&
      !cpfValidate(rowCreating.cpf)
    ) {
      handleErrorMessage('CPF inválido!');
      return;
    }

    if (
      (!rowCreating.full_name || rowCreating.full_name === '') &&
      (!rowCreating.nick_name || rowCreating.nick_name === '')
    ) {
      handleErrorMessage('O NOME ou APELIDO é obrigatório!');
      return;
    }

    if (
      (!rowCreating.phone_number || rowCreating.phone_number === '') &&
      (!rowCreating.email || rowCreating.email === '')
    ) {
      handleErrorMessage('O TELEFONE ou EMAIL é obrigatório!');
      return;
    }

    if (
      rowCreating.phone_number &&
      (rowCreating.phone_number === '' || rowCreating.phone_number.length < 11)
    ) {
      handleErrorMessage('TELEFONE inválido!');
      return;
    }

    let agentId = undefined;

    if (
      selectedAgent.permission === 'admin' ||
      (selectedAgent.permission === 'admin-super' &&
        Number(rowCreating.agent_id) > 0)
    ) {
      agentId = rowCreating.agent_id;
    } else if (
      selectedAgent.permission !== 'admin' &&
      selectedAgent.permission !== 'admin-super'
    ) {
      agentId = selectedAgent.id;
    }

    const params: RequestCreateOrUpdateContact = {
      client_data: {
        agent_id: agentId,
        cpf: rowCreating.cpf !== '' ? rowCreating.cpf : undefined,
        email: rowCreating.email,
        full_name: rowCreating.full_name,
        monthly_income:
          Number(String(rowCreating.monthly_income).replace(/[^0-9]/g, '')) > 0
            ? Number(String(rowCreating.monthly_income).replace(/[^0-9]/g, ''))
            : undefined,
        nick_name: rowCreating.nick_name,
        phone_number:
          Number(String(rowCreating.phone_number).replace(/[^0-9]/g, '')) > 0
            ? Number(String(rowCreating.phone_number).replace(/[^0-9]/g, ''))
            : undefined,
        verified_phone: false,
      },
      call_data: {
        enterprise: rowCreating.enterprise !== '' && rowCreating.enterprise,
        shared_financing: rowCreating.shared_financing,
        type: 'agenda',
        origin: rowCreating.origin ? rowCreating.origin : '',
      },
    };

    setWaiting(true);

    const saveContact = await props.callbackCreate(params);

    setWaiting(false);

    if (saveContact.status !== 200) {
      handleErrorMessage(String(saveContact.data.message));
      return;
    }

    setContacts([saveContact.data, ...contacts]);

    setContactsSearch([saveContact.data, ...contactsSearch]);

    setIsCreating(false);
    setRowCreating({});
  };

  const handleSaveEditedRow = async () => {
    if (!isEditing) {
      return;
    }

    if (
      rowEditing.cpf &&
      rowEditing.cpf !== '' &&
      !cpfValidate(rowEditing.cpf)
    ) {
      handleErrorMessage('CPF inválido!');
      return;
    }

    if (
      (!rowEditing.full_name || rowEditing.full_name === '') &&
      (!rowEditing.nick_name || rowEditing.nick_name === '')
    ) {
      handleErrorMessage('O APELIDO é obrigatório!');
      return;
    }

    if (
      (!rowEditing.phone_number || rowEditing.phone_number === '') &&
      (!rowEditing.email || rowEditing.email === '')
    ) {
      handleErrorMessage('O TELEFONE ou EMAIL é obrigatório!');
      return;
    }

    if (
      rowEditing.phone_number &&
      (rowEditing.phone_number === '' || rowEditing.phone_number.length < 11)
    ) {
      handleErrorMessage('TELEFONE inválido!');
      return;
    }

    let agentId = undefined;

    if (
      selectedAgent.permission === 'admin' ||
      (selectedAgent.permission === 'admin-super' &&
        Number(rowEditing.agent_id) > 0)
    ) {
      agentId = rowEditing.agent_id;
    } else if (
      selectedAgent.permission !== 'admin' &&
      selectedAgent.permission !== 'admin-super'
    ) {
      agentId = selectedAgent.id;
    }

    const params: RequestCreateOrUpdateContact = {
      client_data: {
        agent_id: agentId,
        cpf: rowEditing.cpf !== '' ? rowEditing.cpf : undefined,
        email: rowEditing.email,
        full_name: rowEditing.full_name,
        monthly_income:
          Number(String(rowEditing.monthly_income).replace(/[^0-9]/g, '')) > 0
            ? Number(String(rowEditing.monthly_income).replace(/[^0-9]/g, ''))
            : undefined,
        nick_name: rowEditing.nick_name,
        phone_number:
          Number(String(rowEditing.phone_number).replace(/[^0-9]/g, '')) > 0
            ? Number(String(rowEditing.phone_number).replace(/[^0-9]/g, ''))
            : undefined,
        verified_phone: false,
      },
      call_data: {
        enterprise: rowEditing.enterprise !== '' && rowEditing.enterprise,
        shared_financing: rowEditing.shared_financing,
        type: 'agenda',
        origin: rowEditing.origin ? rowEditing.origin : '',
      },
      id: rowEditing.id,
    };

    setWaiting(true);

    const updateContact = await props.callbackUpdate(params);

    setWaiting(false);

    if (updateContact.status !== 200) {
      handleErrorMessage(String(updateContact.data.message));
      return;
    }

    const contact = [...contacts];
    const search = [...contactsSearch];
    const indexSearch = search.findIndex((item) => item.id === rowEditing.id);

    const contactParam = {
      id: rowEditing.id,
      full_name: params.client_data.full_name,
      nick_name: params.client_data.nick_name,
      cpf: params.client_data.cpf,
      email: params.client_data.email,
      phone_number: String(params.client_data.phone_number),
      agent_id: params.client_data.agent_id,
      enterprise: params.call_data.enterprise,
      origin: params.call_data.origin,
      monthly_income: params.client_data.monthly_income,
      type: params.call_data.type,
      shared_financing: params.call_data.shared_financing,
      created_at: rowEditing.created_at,
      updated_at: rowEditing.updated_at,
      is_discarded: rowEditing.is_discarded,
    };

    if (typeof indexEdit === 'number') {
      contact[indexEdit] = contactParam;
    }

    search[indexSearch] = contactParam;

    setContactsSearch(search);
    setContacts(contact);
    setIsEditing(false);
    setIndexEdit(false);
    setRowEditing({});
  };

  const handleChangeInputEdit = (column: string, value: string | boolean) => {
    setIsEditing(true);

    switch (column) {
      case 'shared_financing':
        setRowEditing({
          ...rowEditing,
          shared_financing: !rowEditing.shared_financing,
        });
        break;

      case 'phone_number':
        if (String(value).length > 14) {
          break;
        }
        setRowEditing({
          ...rowEditing,
          phone_number: formatPhoneNumber(String(value)),
        });
        break;

      case 'cpf':
        if (String(value).length > 14) {
          break;
        }
        setRowEditing({
          ...rowEditing,
          cpf: formatCpf(String(value)),
        });
        break;

      case 'monthly_income':
        const monthly_income = String(value).replace(/[^0-9]/g, '');
        setRowEditing({
          ...rowEditing,
          monthly_income:
            Number(monthly_income).toLocaleString('pt-BR') !== '0'
              ? Number(monthly_income).toLocaleString('pt-BR')
              : undefined,
        });
        break;

      default:
        setRowEditing({
          ...rowEditing,
          [column]: value,
        });
        break;
    }
  };

  const handleChangeInputCreate = (column: string, value: string | boolean) => {
    setIsCreating(true);

    switch (column) {
      case 'shared_financing':
        setRowCreating({
          ...rowCreating,
          shared_financing: !rowCreating.shared_financing,
        });
        break;

      case 'phone_number':
        if (String(value).length > 14) {
          break;
        }
        setRowCreating({
          ...rowCreating,
          phone_number: formatPhoneNumber(String(value)),
        });
        break;

      case 'cpf':
        if (String(value).length > 14) {
          break;
        }
        setRowCreating({
          ...rowCreating,
          cpf: formatCpf(String(value)),
        });
        break;

      case 'monthly_income':
        const monthly_income = String(value).replace(/[^0-9]/g, '');
        setRowCreating({
          ...rowCreating,
          monthly_income:
            Number(monthly_income).toLocaleString('pt-BR') !== '0'
              ? Number(monthly_income).toLocaleString('pt-BR')
              : undefined,
        });
        break;

      default:
        setRowCreating({
          ...rowCreating,
          [column]: value,
        });
        break;
    }
  };

  const handleSetContactDiscarded = async (id: number) => {
    if (!id || id <= 0) {
      return;
    }

    setWaitingDiscarded(id);

    const updateContact = await props.callbackStatus(id);

    if (updateContact.status !== 200) {
      handleErrorMessage(String(updateContact.data.message));
      return;
    }

    const data = [...contacts];

    const indexDiscarded = data.findIndex((item) => item.id === id);

    const setContact = {
      ...data[indexDiscarded],
      is_descarted: true,
    };

    data.splice(indexDiscarded, 1);

    setContacts([...data]);
    setContactsDiscarded([setContact, ...contactsDiscarded]);
    setWaitingDiscarded(0);
  };

  const handleSetContactActive = async (id: number) => {
    if (!id || id <= 0) {
      return;
    }

    setWaitingDiscarded(id);

    const updateContact = await props.callbackStatus(id);

    if (updateContact.status !== 200) {
      handleErrorMessage(String(updateContact.data.message));
      return;
    }

    const data = [...contactsDiscarded];

    const indexActive = data.findIndex((item) => item.id === id);

    const setContact = {
      ...data[indexActive],
      is_descarted: false,
    };

    data.splice(indexActive, 1);

    setContacts([setContact, ...contacts]);
    setContactsDiscarded([...data]);
    setWaitingDiscarded(0);
  };

  const findNameAgentByAgentId = (agent_id: number) => {
    const index = listAgents && listAgents.find((item) => item.id === agent_id);

    return index?.full_name;
  };

  const formatPhoneNumber = (phone_number: string) => {
    const number = phone_number.replace(/[^0-9]/g, '');

    const formated = number.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');

    return formated;
  };

  const formatCpf = (cpf: string) => {
    const number = cpf.replace(/[^0-9]/g, '');

    const formated = number.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      '$1.$2.$3-$4',
    );

    return formated;
  };

  const handleErrorMessage = (message: string) => {
    setErrorMessage({
      isShow: true,
      message,
    });

    setTimeout(() => {
      setErrorMessage({
        isShow: false,
        message: '',
      });
    }, 4000);
  };

  const handleSearchContacts = (e: string, from: string) => {
    const search = e.toLowerCase().replace(/[^a-z0-9 ]/g, '');
    setInputSearch(search);

    if (e === '') {
      setContacts(contactsSearch);
      setContactsDiscarded(contactsDiscardedSearch);
      return;
    }

    if (from === 'active') {
      const filter = contactsSearch.filter(
        (item) =>
          normalizeText(String(item.full_name ? item.full_name : '')).includes(
            search,
          ) ||
          normalizeText(String(item.nick_name ? item.nick_name : '')).includes(
            search,
          ) ||
          normalizeText(String(item.origin ? item.origin : '')).includes(
            search,
          ) ||
          String(item.phone_number ? item.phone_number : '').includes(search) ||
          removeSpecialCaracteres(item.cpf).includes(search),
      );

      setContacts(filter);
    }

    if (from === 'discarded') {
      const filter = contactsDiscardedSearch.filter(
        (item) =>
          normalizeText(String(item.full_name ? item.full_name : '')).includes(
            search,
          ) ||
          normalizeText(String(item.nick_name ? item.nick_name : '')).includes(
            search,
          ) ||
          normalizeText(String(item.origin ? item.origin : '')).includes(
            search,
          ) ||
          String(item.phone_number ? item.phone_number : '').includes(search) ||
          removeSpecialCaracteres(item.cpf).includes(search),
      );

      setContactsDiscarded(filter);
    }

    if (from === 'conversions') {
      const filter = contactsConversionsSearch.filter(
        (item) =>
          normalizeText(String(item.full_name ? item.full_name : '')).includes(
            search,
          ) ||
          normalizeText(String(item.nick_name ? item.nick_name : '')).includes(
            search,
          ) ||
          normalizeText(String(item.origin ? item.origin : '')).includes(
            search,
          ) ||
          String(item.phone_number ? item.phone_number : '').includes(search) ||
          removeSpecialCaracteres(item.cpf).includes(search),
      );

      setContactsConversions(filter);
    }
  };

  const handleNumberCpfOrPhoneNumber = async () => {
    if (numberVerify.length === 11) {
      setWaintingVerify(true);
      const verify = await props.callbackVerify(numberVerify);
      setWaintingVerify(false);

      setMessageVerify({
        message: verify.data.message,
        agent:
          verify.data.agent_id && findNameAgentByAgentId(verify.data.agent_id),
      });
    }
  };

  const handleChangeCpfOrPhoneNumber = (value: string) => {
    setMessageVerify({
      agent: undefined,
      message: '',
    });

    const number = value.replace(/[^0-9]/g, '');

    if (number.length <= 11) {
      setNumberVerify(number);
    }
  };

  const handleFilterByDate = (param: DayRange, from: string) => {
    setDateFilter(param);

    if (
      param.from !== undefined &&
      param.from !== null &&
      param.to !== undefined &&
      param.to !== null
    ) {
      if (from === 'active') {
        const filtered = getDataFilteredByDate(param, contactsSearch);
        filtered && setContacts(filtered);
      }

      if (from === 'discarded') {
        const filtered = getDataFilteredByDate(param, contactsDiscardedSearch);
        filtered && setContactsDiscarded(filtered);
      }

      if (from === 'conversions') {
        const filtered = getDataFilteredByDate(
          param,
          contactsConversionsSearch,
        );
        filtered && setContactsConversions(filtered);
      }
    }
  };

  const getDataFilteredByDate = (param: DayRange, data: ContactsProps[]) => {
    console.log(param);
    if (
      param.from !== undefined &&
      param.from !== null &&
      param.to !== undefined &&
      param.to !== null
    ) {
      const call_date_from = new Date(
        Number(param.from?.year),
        Number(param.from?.month) - 1,
        Number(param.from?.day),
      ).setHours(0, 0, 0);
      const call_date_to = new Date(
        Number(param.to?.year),
        Number(param.to?.month) - 1,
        Number(param.to?.day),
      ).setHours(23, 59, 59, 999);

      const filtered = data.filter(
        (item) =>
          new Date(item.created_at).getTime() >= call_date_from &&
          new Date(item.created_at).getTime() <= call_date_to,
      );

      return filtered;
    } else {
      return undefined;
    }
  };

  const getValueFormatDate = () => {
    const dateValue = dateFilter;

    if (
      dateValue.from !== undefined &&
      dateValue.from !== null &&
      dateValue.to !== undefined &&
      dateValue.to !== null
    ) {
      const call_date_from = new Date(
        Number(dateValue.from?.year),
        Number(dateValue.from?.month) - 1,
        Number(dateValue.from?.day),
      ).getTime();
      const call_date_to = new Date(
        Number(dateValue.to?.year),
        Number(dateValue.to?.month) - 1,
        Number(dateValue.to?.day),
      ).getTime();

      const dateFrom = moment(call_date_from).format('DD/MM/YYYY');

      const dateTo = moment(call_date_to).format('DD/MM/YYYY');

      return 'De ' + dateFrom + ' Até ' + dateTo;
    } else {
      return '';
    }
  };

  const handleShowActive = (param: string) => {
    if (param === 'active') {
      setContacts(contactsSearch);
    }

    if (param === 'discarded') {
      setContactsDiscarded(contactsDiscardedSearch);
    }

    if (param === 'conversions') {
      setContactsConversions(contactsConversionsSearch);
    }
    setDateFilter({
      from: null,
      to: null,
    });
    setShowActive(param);
  };

  useEffect(() => {
    if (props.contactData) {
      const discarded = props.contactData.filter(
        (item) => item.is_discarded && item.available,
      );
      const noDiscarded = props.contactData.filter(
        (item) => !item.is_discarded && item.available,
      );
      const conversions = props.contactData.filter((item) => !item.available);
      setContacts(noDiscarded);
      setContactsSearch(noDiscarded);
      setContactsDiscarded(discarded);
      setContactsDiscardedSearch(discarded);
      setContactsConversions(conversions);
      setContactsConversionsSearch(conversions);
    }

    const agent = JSON.parse(sessionStorage.getItem('selectedAgent'));
    setSelectedAgent(agent);

    props.agents &&
      setListAgents([
        {
          id: 0,
          full_name: 'Sem corretor',
          cpf: '',
          email: '',
          number_of_leads: 0,
          password_hash: '',
          permission: '',
        },
        ...props.agents,
      ]);

    props.enterpriseData &&
      setEnterpriseSelect([
        {
          city: '',
          enterprises: [{ name: 'Sem empreendimento', group_dashboard: '' }],
        },
        ...props.enterpriseData,
      ]);
  }, [props.contactData, props.agents, props.enterpriseData]);

  return (
    <Content>
      {errorMessage.isShow && <AlertMessage message={errorMessage.message} />}
      <Header>
        <Tabs>
          <span className={showActive === 'active' ? 'selected' : ''}>
            <button
              onClick={() => {
                handleShowActive('active');
              }}
            >
              Contatos Ativos
            </button>
          </span>
          <span className={showActive === 'discarded' ? 'selected' : ''}>
            <button
              onClick={() => {
                handleShowActive('discarded');
              }}
            >
              Contatos Descartados
            </button>
          </span>
          <span className={showActive === 'conversions' ? 'selected' : ''}>
            <button
              onClick={() => {
                handleShowActive('conversions');
              }}
            >
              Contatos Convertidos
            </button>
          </span>
          <span className={showActive === 'searchContact' ? 'selected' : ''}>
            <button
              onClick={() => {
                handleShowActive('searchContact');
              }}
            >
              Buscar
            </button>
          </span>
        </Tabs>
        {showActive !== 'searchContact' && (
          <div className="header">
            <SearchDate>
              <div className="filterDate">
                <span>
                  <DatePicker
                    value={dateFilter}
                    onChange={(e: DayRange) =>
                      handleFilterByDate(e, showActive)
                    }
                    inputPlaceholder="Filtrar por data de entrada"
                    locale={myCustomLocale}
                    formatInputText={getValueFormatDate}
                    renderFooter={() => (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: '1rem 2rem',
                        }}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            handleShowActive(showActive);
                          }}
                          style={{
                            border: 'none',
                            background: '#0eca2d',
                            color: '#fff',
                            borderRadius: '0.5rem',
                            padding: '0.5rem 2rem',
                          }}
                        >
                          Limpar filtro
                        </button>
                      </div>
                    )}
                  />
                </span>
              </div>
            </SearchDate>
            <Search>
              <div>
                <input
                  onChange={(e) => {
                    handleSearchContacts(String(e.target.value), showActive);
                  }}
                  type="text"
                  placeholder=" Pesquisar por cpf, nome, apelido, origem ou telefone"
                  value={inputSearch}
                />
              </div>
            </Search>
            {/* <CreateContact>
                <button disabled={showActive !== 'active'} onClick={handleShowCreateRow}>
                  <FiPlusCircle />
                  CRIAR NOVO
                </button>
                <ButtonCloseCollapse>
                  <button onClick={props.handleShowContactModal}>
                    <FiChevronLeft />
                  </button>
                </ButtonCloseCollapse>
              </CreateContact> */}
          </div>
        )}
      </Header>
      <ButtonXLS>
        <div className="row">
          <div className="countZapVivaReal col-md-6 row">
            <div className="col-md-3">
              Contatos VivaReal -{' '}
              {contacts.filter((item) => item.origin === 'VivaReal').length}
            </div>
            <div className="col-md-3">
              Contatos Zap -{' '}
              {contacts.filter((item) => item.origin === 'Zap').length}
            </div>
            <div className="col-md-3">
              Total -{' '}
              {contacts.filter((item) => item.origin === 'Zap').length +
                contacts.filter((item) => item.origin === 'VivaReal').length}
            </div>
          </div>
        </div>
      </ButtonXLS>
      {showActive === 'active' && (
        <TableContent waiting={waiting}>
          <TableContainer component={Paper}>
            <Table id="table-to-xls">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ width: '13%', minWidth: 150, textAlign: 'left' }}
                  >
                    NOME
                  </TableCell>
                  <TableCell
                    style={{ width: '8%', minWidth: 90, textAlign: 'left' }}
                  >
                    APELIDO
                  </TableCell>
                  <TableCell
                    style={{ width: '13%', minWidth: 140, textAlign: 'left' }}
                  >
                    EMAIL
                  </TableCell>
                  <TableCell
                    style={{ width: '8%', minWidth: 75, textAlign: 'left' }}
                  >
                    TELEFONE
                  </TableCell>
                  <TableCell
                    style={{ width: '8%', minWidth: 75, textAlign: 'left' }}
                  >
                    CPF
                  </TableCell>
                  <TableCell
                    style={{ width: '6%', minWidth: 80, textAlign: 'left' }}
                  >
                    RENDA MENSAL
                  </TableCell>
                  <TableCell
                    style={{ width: '5%', minWidth: 80, textAlign: 'left' }}
                  >
                    COMPARTILHADO
                  </TableCell>
                  <TableCell
                    style={{ width: '10%', minWidth: 150, textAlign: 'left' }}
                  >
                    EMPREENDIMENTO
                  </TableCell>
                  <TableCell
                    style={{ width: '7%', minWidth: 80, textAlign: 'left' }}
                  >
                    ORIGEM
                  </TableCell>
                  <TableCell
                    style={{ width: '9%', minWidth: 80, textAlign: 'left' }}
                  >
                    CORRETOR
                  </TableCell>
                  <TableCell
                    style={{ width: '4%', minWidth: 60, textAlign: 'left' }}
                  >
                    DATA DE ENTRADA
                  </TableCell>
                  <TableCell
                    style={{ width: '5%', textAlign: 'center' }}
                  ></TableCell>
                  <TableCell
                    style={{ width: '5%', textAlign: 'center' }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isCreating && (
                  <TableRow>
                    <TableCell scope="row">
                      <input
                        onChange={(e) => {
                          handleChangeInputCreate('full_name', e.target.value);
                        }}
                        type="text"
                        value={rowCreating.full_name}
                      />
                    </TableCell>

                    <TableCell align="left">
                      <input
                        onChange={(e) => {
                          handleChangeInputCreate('nick_name', e.target.value);
                        }}
                        type="text"
                        value={rowCreating.nick_name}
                      />
                    </TableCell>

                    <TableCell align="left">
                      <input
                        onChange={(e) => {
                          handleChangeInputCreate('email', e.target.value);
                        }}
                        type="text"
                        value={rowCreating.email}
                      />
                    </TableCell>

                    <TableCell align="left">
                      <input
                        onChange={(e) => {
                          handleChangeInputCreate(
                            'phone_number',
                            e.target.value,
                          );
                        }}
                        type="text"
                        value={rowCreating.phone_number}
                      />
                    </TableCell>

                    <TableCell align="left">
                      <input
                        onChange={(e) => {
                          handleChangeInputCreate('cpf', e.target.value);
                        }}
                        type="text"
                        value={rowCreating.cpf}
                      />
                    </TableCell>

                    <TableCell align="left">
                      <input
                        onChange={(e) => {
                          handleChangeInputCreate(
                            'monthly_income',
                            e.target.value,
                          );
                        }}
                        type="text"
                        value={
                          rowCreating.monthly_income &&
                          String(rowCreating.monthly_income)
                        }
                      />
                    </TableCell>

                    <TableCell align="left">
                      <input
                        onChange={(e) => {
                          handleChangeInputCreate(
                            'shared_financing',
                            e.target.value,
                          );
                        }}
                        type="checkbox"
                        checked={rowCreating.shared_financing}
                      />
                    </TableCell>

                    <TableCell align="left">
                      <select
                        defaultValue=""
                        value={rowCreating.enterprise}
                        onChange={(e) => {
                          handleChangeInputCreate('enterprise', e.target.value);
                        }}
                      >
                        {enterproseSelect.map((item, index) => (
                          <optgroup label={item.city} key={index}>
                            {item.enterprises.map((enterprise, indexEnt) => (
                              <option key={indexEnt} value={enterprise.name}>
                                {enterprise.name}
                              </option>
                            ))}
                          </optgroup>
                        ))}
                      </select>
                    </TableCell>

                    <TableCell align="left">
                      <input
                        onChange={(e) => {
                          handleChangeInputCreate('origin', e.target.value);
                        }}
                        type="text"
                        value={rowCreating.origin}
                      />
                    </TableCell>

                    <TableCell align="left" style={{ paddingRight: 16 }}>
                      {selectedAgent.permission === 'admin' ||
                      selectedAgent.permission === 'admin-super' ? (
                        <select
                          defaultValue={0}
                          value={rowCreating.agent_id}
                          onChange={(e) => {
                            handleChangeInputCreate('agent_id', e.target.value);
                          }}
                        >
                          {listAgents.map((agent, index) => (
                            <option key={index} value={agent.id}>
                              {agent.full_name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        selectedAgent.full_name
                      )}
                    </TableCell>

                    <TableCell align="left"></TableCell>

                    <TableCell align="center">
                      <button
                        className={waiting ? '' : 'btn-tooltip'}
                        data-tooltip="Cancelar"
                        disabled={waiting ? true : false}
                        onClick={handleShowCreateRow}
                      >
                        <FiX fontSize={15} />
                      </button>
                    </TableCell>
                    <TableCell align="left">
                      <button
                        className={waiting ? '' : 'btn-tooltip'}
                        data-tooltip="Salvar"
                        disabled={waiting ? true : false}
                        onClick={handleSaveNewRow}
                      >
                        {waiting ? (
                          <ImSpinner2 className="spin" />
                        ) : (
                          <FiCheck fontSize={15} />
                        )}
                      </button>
                    </TableCell>
                  </TableRow>
                )}
                {contacts.map((item, index) => {
                  if (indexEdit === index) {
                    return (
                      <TableRow key={index}>
                        <TableCell scope="row">
                          <input
                            onChange={(e) => {
                              handleChangeInputEdit(
                                'full_name',
                                e.target.value,
                              );
                            }}
                            type="text"
                            value={rowEditing.full_name}
                          />
                        </TableCell>

                        <TableCell align="left">
                          <input
                            onChange={(e) => {
                              handleChangeInputEdit(
                                'nick_name',
                                e.target.value,
                              );
                            }}
                            type="text"
                            value={rowEditing.nick_name}
                          />
                        </TableCell>

                        <TableCell align="left">
                          <input
                            onChange={(e) => {
                              handleChangeInputEdit('email', e.target.value);
                            }}
                            type="text"
                            value={rowEditing.email}
                          />
                        </TableCell>

                        <TableCell align="left">
                          <input
                            onChange={(e) => {
                              handleChangeInputEdit(
                                'phone_number',
                                e.target.value,
                              );
                            }}
                            type="text"
                            value={rowEditing.phone_number}
                          />
                        </TableCell>

                        <TableCell align="left">
                          <input
                            onChange={(e) => {
                              handleChangeInputEdit('cpf', e.target.value);
                            }}
                            type="text"
                            value={rowEditing.cpf}
                          />
                        </TableCell>

                        <TableCell align="left">
                          <input
                            onChange={(e) => {
                              handleChangeInputEdit(
                                'monthly_income',
                                e.target.value,
                              );
                            }}
                            type="text"
                            value={
                              rowEditing.monthly_income &&
                              String(rowEditing.monthly_income)
                            }
                          />
                        </TableCell>

                        <TableCell align="left">
                          <input
                            onChange={(e) => {
                              handleChangeInputEdit(
                                'shared_financing',
                                e.target.value,
                              );
                            }}
                            type="checkbox"
                            checked={rowEditing.shared_financing}
                          />
                        </TableCell>

                        <TableCell align="left">
                          <select
                            value={
                              rowEditing.enterprise ? rowEditing.enterprise : ''
                            }
                            defaultValue=""
                            onChange={(e) => {
                              handleChangeInputEdit(
                                'enterprise',
                                e.target.value,
                              );
                            }}
                          >
                            {enterproseSelect.map((enterprises, indexEnts) => (
                              <optgroup
                                label={enterprises.city}
                                key={indexEnts}
                              >
                                {enterprises.enterprises.map(
                                  (enterprise, indexEnt) => (
                                    <option
                                      key={indexEnt}
                                      value={enterprise.name}
                                    >
                                      {enterprise.name}
                                    </option>
                                  ),
                                )}
                              </optgroup>
                            ))}
                          </select>
                        </TableCell>

                        <TableCell align="left">
                          <input
                            onChange={(e) => {
                              handleChangeInputEdit('origin', e.target.value);
                            }}
                            type="text"
                            value={rowEditing.origin}
                          />
                        </TableCell>

                        <TableCell align="left" style={{ paddingRight: 16 }}>
                          {selectedAgent.permission === 'admin' ||
                          selectedAgent.permission === 'admin-super' ? (
                            <select
                              defaultValue={0}
                              value={rowEditing.agent_id}
                              onChange={(e) => {
                                handleChangeInputEdit(
                                  'agent_id',
                                  e.target.value,
                                );
                              }}
                            >
                              {listAgents.map((agent, index) => (
                                <option key={index} value={agent.id}>
                                  {agent.full_name}
                                </option>
                              ))}
                            </select>
                          ) : (
                            selectedAgent.full_name
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {moment(rowEditing.created_at).format('DD/MM/YY')}
                        </TableCell>

                        <TableCell align="center">
                          <button
                            className={waiting ? '' : 'btn-tooltip'}
                            data-tooltip="Cancelar"
                            disabled={waiting ? true : false}
                            onClick={() => setIndexEdit(false)}
                          >
                            <FiX fontSize={15} />
                          </button>
                        </TableCell>
                        <TableCell align="left">
                          <button
                            className={waiting ? '' : 'btn-tooltip'}
                            data-tooltip="Salvar"
                            disabled={waiting ? true : false}
                            onClick={handleSaveEditedRow}
                          >
                            {waiting ? (
                              <ImSpinner2 className="spin" />
                            ) : (
                              <FiCheck fontSize={15} />
                            )}
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  } else {
                    return (
                      <TableRow key={index}>
                        <TableCell scope="row"> {item.full_name}</TableCell>
                        <TableCell align="left">{item.nick_name}</TableCell>
                        <TableCell align="left">{item.email}</TableCell>
                        <TableCell align="left">
                          {item.phone_number &&
                            formatPhoneNumber(String(item.phone_number))}
                        </TableCell>
                        <TableCell align="left">
                          {item.cpf && formatCpf(String(item.cpf))}
                        </TableCell>
                        <TableCell align="left">
                          {item.monthly_income &&
                            `R$ ${Number(item.monthly_income).toLocaleString(
                              'pt-BR',
                            )}`}
                        </TableCell>
                        <TableCell align="center">
                          {item.shared_financing ? 'Sim' : 'Não'}
                        </TableCell>
                        <TableCell align="left">{item.enterprise}</TableCell>
                        <TableCell align="left">{item.origin}</TableCell>
                        <TableCell align="left" style={{ paddingRight: 16 }}>
                          {item.agent_id
                            ? findNameAgentByAgentId(Number(item.agent_id))
                            : ''}
                        </TableCell>
                        <TableCell align="left">
                          {moment(item.created_at).format('DD/MM/YY')}
                        </TableCell>
                        <TableCell align="center">
                          <button
                            className="btn-tooltip"
                            data-tooltip="Editar"
                            onClick={(e) => {
                              handleShowEditRow(index);
                            }}
                          >
                            <FiEdit fontSize={15} />
                          </button>
                        </TableCell>
                        <TableCell align="left">
                          <button
                            className="btn-tooltip"
                            data-tooltip="Descartar"
                            onClick={() => {
                              handleSetContactDiscarded(item.id);
                            }}
                          >
                            {waitingDiscarded !== item.id ? (
                              <FiTrash fontSize={15} />
                            ) : (
                              <ImSpinner2 className="spin" fontSize={15} />
                            )}
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </TableContent>
      )}
      {showActive === 'discarded' && (
        <TableContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ width: '15%', minWidth: 150, textAlign: 'left' }}
                  >
                    NOME
                  </TableCell>
                  <TableCell
                    style={{ width: '10%', minWidth: 120, textAlign: 'left' }}
                  >
                    APELIDO
                  </TableCell>
                  <TableCell
                    style={{ width: '15%', minWidth: 150, textAlign: 'left' }}
                  >
                    EMAIL
                  </TableCell>
                  <TableCell
                    style={{ width: '8%', minWidth: 80, textAlign: 'left' }}
                  >
                    TELEFONE
                  </TableCell>
                  <TableCell
                    style={{ width: '8%', minWidth: 80, textAlign: 'left' }}
                  >
                    CPF
                  </TableCell>
                  <TableCell
                    style={{ width: '8%', minWidth: 80, textAlign: 'left' }}
                  >
                    RENDA MENSAL
                  </TableCell>
                  <TableCell
                    style={{ width: '5%', minWidth: 80, textAlign: 'left' }}
                  >
                    COMPARTILHADO
                  </TableCell>
                  <TableCell
                    style={{ width: '10%', minWidth: 150, textAlign: 'left' }}
                  >
                    EMPREENDIMENTO
                  </TableCell>
                  <TableCell
                    style={{ width: '10%', minWidth: 100, textAlign: 'left' }}
                  >
                    ORIGEM
                  </TableCell>
                  <TableCell
                    style={{ width: '8%', minWidth: 80, textAlign: 'left' }}
                  >
                    CORRETOR
                  </TableCell>
                  <TableCell
                    style={{ width: '5%', minWidth: 60, textAlign: 'left' }}
                  >
                    DATA DE ENTRADA
                  </TableCell>
                  <TableCell
                    style={{ width: '5%', minWidth: 80, textAlign: 'center' }}
                  >
                    RESTAURAR
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contactsDiscarded.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell scope="row"> {item.full_name}</TableCell>
                    <TableCell align="left">{item.nick_name}</TableCell>
                    <TableCell align="left">{item.email}</TableCell>
                    <TableCell align="left">
                      {item.phone_number &&
                        formatPhoneNumber(String(item.phone_number))}
                    </TableCell>
                    <TableCell align="left">
                      {item.cpf && formatCpf(String(item.cpf))}
                    </TableCell>
                    <TableCell align="left">
                      {item.monthly_income &&
                        `R$ ${Number(item.monthly_income).toLocaleString(
                          'pt-BR',
                        )}`}
                    </TableCell>
                    <TableCell align="center">
                      {item.shared_financing ? 'Sim' : 'Não'}
                    </TableCell>
                    <TableCell align="left">{item.enterprise}</TableCell>
                    <TableCell align="left">{item.origin}</TableCell>
                    <TableCell align="left">
                      {item.agent_id
                        ? findNameAgentByAgentId(Number(item.agent_id))
                        : ''}
                    </TableCell>
                    <TableCell align="left">
                      {moment(item.created_at).format('DD/MM/YY')}
                    </TableCell>
                    <TableCell align="center">
                      <button
                        className="btn-tooltip"
                        data-tooltip="Restaurar"
                        onClick={() => {
                          handleSetContactActive(item.id);
                        }}
                      >
                        {waitingDiscarded !== item.id ? (
                          <FiCheck fontSize={15} />
                        ) : (
                          <ImSpinner2 className="spin" fontSize={15} />
                        )}
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TableContent>
      )}
      {showActive === 'conversions' && (
        <TableContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ width: '15%', minWidth: 150, textAlign: 'left' }}
                  >
                    NOME
                  </TableCell>
                  <TableCell
                    style={{ width: '10%', minWidth: 120, textAlign: 'left' }}
                  >
                    APELIDO
                  </TableCell>
                  <TableCell
                    style={{ width: '15%', minWidth: 150, textAlign: 'left' }}
                  >
                    EMAIL
                  </TableCell>
                  <TableCell
                    style={{ width: '8%', minWidth: 80, textAlign: 'left' }}
                  >
                    TELEFONE
                  </TableCell>
                  <TableCell
                    style={{ width: '8%', minWidth: 80, textAlign: 'left' }}
                  >
                    CPF
                  </TableCell>
                  <TableCell
                    style={{ width: '8%', minWidth: 80, textAlign: 'left' }}
                  >
                    RENDA MENSAL
                  </TableCell>
                  <TableCell
                    style={{ width: '5%', minWidth: 80, textAlign: 'left' }}
                  >
                    COMPARTILHADO
                  </TableCell>
                  <TableCell
                    style={{ width: '10%', minWidth: 150, textAlign: 'left' }}
                  >
                    EMPREENDIMENTO
                  </TableCell>
                  <TableCell
                    style={{ width: '10%', minWidth: 100, textAlign: 'left' }}
                  >
                    ORIGEM
                  </TableCell>
                  <TableCell
                    style={{ width: '9%', minWidth: 80, textAlign: 'left' }}
                  >
                    CORRETOR
                  </TableCell>
                  <TableCell
                    style={{ width: '4%', minWidth: 60, textAlign: 'left' }}
                  >
                    DATA DE ENTRADA
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contactsConversions.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell scope="row"> {item.full_name}</TableCell>
                    <TableCell align="left">{item.nick_name}</TableCell>
                    <TableCell align="left">{item.email}</TableCell>
                    <TableCell align="left">
                      {item.phone_number &&
                        formatPhoneNumber(String(item.phone_number))}
                    </TableCell>
                    <TableCell align="left">
                      {item.cpf && formatCpf(String(item.cpf))}
                    </TableCell>
                    <TableCell align="left">
                      {item.monthly_income &&
                        `R$ ${Number(item.monthly_income).toLocaleString(
                          'pt-BR',
                        )}`}
                    </TableCell>
                    <TableCell align="center">
                      {item.shared_financing ? 'Sim' : 'Não'}
                    </TableCell>
                    <TableCell align="left">{item.enterprise}</TableCell>
                    <TableCell align="left">{item.origin}</TableCell>
                    <TableCell align="left">
                      {item.agent_id
                        ? findNameAgentByAgentId(Number(item.agent_id))
                        : ''}
                    </TableCell>
                    <TableCell align="left">
                      {moment(item.created_at).format('DD/MM/YY')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TableContent>
      )}

      {showActive === 'searchContact' && (
        <TableContent>
          <SearchContacts>
            <div>
              <span>Verique se o contato já está cadastrado</span>
              <div className="inputVerify">
                <input
                  type="text"
                  onChange={(e) => {
                    handleChangeCpfOrPhoneNumber(e.target.value);
                  }}
                  value={numberVerify}
                  placeholder="Digite o CPF ou TELEFONE"
                />
                <button
                  type="button"
                  onClick={handleNumberCpfOrPhoneNumber}
                  disabled={numberVerify.length === 11 ? false : true}
                >
                  Pesquisar
                </button>
              </div>
              <div className="messageVerify">
                {waitingVerify && (
                  <div>
                    <ImSpinner2 className="spin" fontSize={15} />
                  </div>
                )}
                {messageVerify.message === 'found' && (
                  <span className="unavailableContact">
                    <label>Contato já cadastrado com este número.</label>
                    <label>
                      Corretor:{' '}
                      <strong>
                        {messageVerify.agent
                          ? messageVerify.agent
                          : 'Sem corretor'}
                      </strong>
                    </label>
                  </span>
                )}
                {messageVerify.message === 'not-found' && (
                  <span className="availableContact">
                    <label>Nenhum dado encontrado para o número</label>
                  </span>
                )}
              </div>
            </div>
          </SearchContacts>
        </TableContent>
      )}
    </Content>
  );
};

export default ContactsTable;
