import React, { ReactNode, useEffect, useState } from 'react';
import { AreaClick, Content } from './styles';
import ContactsTable from 'components/ContactsTable';
import api from 'services/emeserver/api';
import authToken from 'services/emeserver/authToken';
import {
  Agent,
  CitiesWithEnterprises,
  ContactsProps,
  RequestCreateOrUpdateContact,
} from 'utils/InterFaces';

interface Props {
  children?: ReactNode;
  agents: Agent[];
  listEnterprise: CitiesWithEnterprises[];
  handleShowContactModal: () => void;
  showModalContacts: boolean;
}

const ContactsModal = (props: Props) => {
  const [contactData, setContactData] = useState([]);
  const handleCreateContact = async (params: RequestCreateOrUpdateContact) => {
    try {
      const create = await api.post(
        '/contact/create',
        { client_data: params.client_data, call_data: params.call_data },
        { headers: { Authorization: authToken.token } },
      );
      return create;
    } catch (error) {
      return error.response;
    }
  };

  const handleUpdateContact = async (params: RequestCreateOrUpdateContact) => {
    try {
      const update = await api.put(
        `/contact/update/${params.id}`,
        { client_data: params.client_data, call_data: params.call_data },
        { headers: { Authorization: authToken.token } },
      );

      return update;
    } catch (error) {
      return error.response;
    }
  };

  const handleUpdateStatus = async (id: number) => {
    try {
      const update = await api.put(
        `/contact/status/${id}`,
        {},
        { headers: { Authorization: authToken.token } },
      );

      return update;
    } catch (error) {
      return error.response;
    }
  };

  const handleVerifyByCpfOrPhoneNumber = async (cpfOrPhoneNumber: string) => {
    try {
      const verify = await api.get(`/contact/consult/${cpfOrPhoneNumber}`, {
        headers: { Authorization: authToken.token },
      });

      return verify;
    } catch (error) {
      return error.response;
    }
  };

  useEffect(() => {
    const run = async () => {
      const selectedAgent = JSON.parse(sessionStorage.getItem('selectedAgent'));
      const routeContact =
        selectedAgent.permission === 'admin' ||
        selectedAgent.permission === 'admin-super'
          ? `/contact/list/admin?agent_id=${selectedAgent.id}`
          : `/contact/${selectedAgent.id}`;
      const { data } = await api.get(routeContact, {
        headers: { Authorization: authToken.token },
      });

      const contactsRes: ContactsProps[] = data;

      const contacts =
        contactsRes.length > 0
          ? contactsRes.filter((item) => item.origin !== 'HM Estelar')
          : [];

      setContactData(contacts);
    };
    run();
  }, []);

  useEffect(() => {
    props.showModalContacts && window.scrollTo(0, 0);
  }, [props.showModalContacts]);

  return (
    <React.Fragment>
      <AreaClick
        is_collapsed={props.showModalContacts}
        onClick={props.handleShowContactModal}
      />
      <Content is_collapsed={props.showModalContacts}>
        <ContactsTable
          handleShowContactModal={props.handleShowContactModal}
          contactData={contactData}
          enterpriseData={props.listEnterprise}
          callbackCreate={handleCreateContact}
          callbackUpdate={handleUpdateContact}
          callbackStatus={handleUpdateStatus}
          callbackVerify={handleVerifyByCpfOrPhoneNumber}
          agents={props.agents}
        />
      </Content>
    </React.Fragment>
  );
};

export default ContactsModal;
