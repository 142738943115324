import styled, { css } from "styled-components";

interface Props{
  is_collapsed?: boolean
}

export const AreaClick = styled.div<Props>`
  z-index: 98;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: transparent;
  transform: translateX(-100vw);
  ${
    props => props.is_collapsed && css`
      transform: translateX(0);
    `
  }
  ${
    props => props.is_collapsed ? 
      (document.body.style.overflow = "hidden") :
      (document.body.style.overflow = "auto")
  }
`;

export const Content = styled.div<Props>`
  z-index: 100;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 82vh;
  width: 100vw;
  transform: translateX(-100vw);
  transition: 0.5s;
  display: flex;
  flex-direction: row;
  ${
    props => props.is_collapsed && css`
      transform: translateX(0);
    `
  }
`;