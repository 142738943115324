import styled from "styled-components";

export const Content = styled.div`
  width: 200px;
  height: 50px;
  position: absolute;
  bottom: 1rem;
  right: 3rem;
  background: rgba(203,51,59,0.8);
  border-radius: 2px;

  div{
    width: 100%;
    height: 100%;
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;

    label{
      color: #fff;
      font-size: 1.1rem;
      font-weight: bold;
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
`;