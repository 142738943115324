import React from "react";
import { Content } from "./styles";

interface Props{
  message: string;
}

const AlertMessage = ({ message }: Props) => {
  return(
    <Content>
      <div>
        <label>
        {message}
        </label>
      </div>
    </Content>
  )
}

export default AlertMessage;