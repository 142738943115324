import styled, { css } from "styled-components";

interface Props {
  is_collapsed?: boolean;
  waiting?: boolean;
  errorRequest?: boolean;
}

export const Content = styled.div`  
  background: #fbfbfb;
  border: 1px solid #e0dfda;
  height: 100%;
  max-height: 82vh;
  width: 99%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`;

export const ButtonXLS = styled.div`  
.download-table-xls-button{
  background-color: #BF000B;
    color: #FFF;
    padding: 10px;
    border: none;
    border-radius: 5px;
}
.row{
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 10px;
}
.countZapVivaReal{
  margin-right: 15px;
  margin-left: 15px;
  font-size: small;
  margin-top: 0.3rem;
}
`;


export const Header = styled.div`
  height: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;

  div.header{
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }

  @media (max-width: 998px) {
    flex-direction: column;
    height: auto;
  }

  @media (max-width: 768px) {

    div.header{
      flex-direction: column;
    }

    svg{
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
`;

export const Tabs = styled.div`
  display: flex;
  padding-left: 0.1rem;

  @media (max-width: 768px){
    
    span{
      button{
        font-size: 0.7rem;
      }
    }
  }

  span{
    padding-left: 1rem;
    padding-right: 1rem;
    padding: 1rem;
    border-radius: 0 0 5px 5px;
    display: flex;
    align-items: center;
    font-weight: bold;

    button{
      border: none;
      background: transparent;
    }

    &.selected{
      background-color: #e0dfda;
    }
  }
`;


export const ButtonCloseCollapse = styled.div<Props>`
  z-index: 100;
  width: 3rem;
  height: 3rem;
  margin-left: 0.5rem;

  @media (max-width: 998px) {
  }

  button{
    border: none;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    background: #e0dfda;
    border-radius: 0 3px 0 3px;

    svg{
      font-size: 2.6rem;
      color: #BF000B;
    }
  }
`;

export const TableContent = styled.div<Props>`
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  padding: 0.5rem;

  .MuiPaper-elevation1 {
    box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 7%), 
    0px 1px 3px 0px rgb(0 0 0 / 5%);
    overflow: scroll;
    max-height: 74vh;
    
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e0dfda;
      border-radius: 4px;
    }
  }

  thead th{
    font-weight: bold;
    text-align: end;
    padding-left: 0.5rem;

    &:first-child{
      text-align: start;
    }
  }

  tbody td{
    padding-left: 2px;
    padding-right: 2px;
    font-size: 1.1rem;
    position: relative;

    input{
      width: 100%;
      border: 1px solid #e0dfda;
      border-radius: 2px;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      font-size: 1.1rem;
    }
    select{
      width: 100%;
      border: 1px solid #e0dfda;
      border-radius: 2px;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      optgroup{
        font-size: 1.2rem;
        color: #e0dfda;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
      option{
        color: rgba(0, 0, 0, 0.87);
        font-size: 1.2rem;
      }
    }

    .btn-tooltip{
      &::after{
        content: attr(data-tooltip);
        background-color: rgba(0, 0, 0, 0.87);
        color: #fff;
        padding: 0.2rem 0.5rem 0.2rem 0.5rem;
        border-radius: 2px;
        font-size: 0.9rem;
        position: absolute;
        top: 0;
        left: -1rem;
        z-index: 102;
        visibility: hidden;
        transition: 0.5s;
      }
      &:hover::after{
          visibility: visible;
      }
      
    }

    button{
      background: transparent;
      border: none;
      cursor: pointer;

      @media (max-width: 768px) {
        svg{
          margin-left: 0.5rem;
          margin-right: 1rem;
        }
      }

      svg.spin{
        animation: spin 2s infinite;
        color: #0D7DC1;
      }
      
      ${props => props.waiting && css`
          &:disabled{
            cursor: not-allowed;
          }
        `
  }
      

      @keyframes spin {
        from{
          transform: rotate(0deg);
          transition: 1s
        }
        to{
          transform: rotate(360deg);
        }
      }
    }
  }
`;



export const CreateContact = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  height: 3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  
  @media (max-width: 768px) {
    margin-top: 0.5rem;
    width: 100%;
  }

  button{
    display: flex;
    background: #e0dfda;
    align-items: center;
    border: none;
    cursor: pointer;
    height: 3rem;
    font-size: 1.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0 0 5px 5px;

    svg{
      font-size: 1.6rem;
      color: #BF000B;
      margin-right: 0.5rem;
    }
  }
`;

export const Search = styled.div`
  padding: 0.5rem 0.2rem 0 0.2rem;
  margin-right: 1rem;
  width: 100%;
  flex: 1;
  input{
    height: 2.5rem;
    width: 100%;
    border: 1px solid #e0dfda;
    border-radius: 5px;
    max-width: 450px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const SearchContacts = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 10%;

  display: flex;
  align-items: center;
  flex-direction: column;

  > div{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    span{
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  .inputVerify{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    input{
      padding-left: 1rem;
      min-width: 30%;
      border: 1px solid #aaaaaa;
      margin-right: 1rem;
      margin-left: 10%;
      border-radius: 3px;
    }

    button{
      border-radius: 3px;
      border: none;
      background: #BF000B;
      color: #fff;
      font-weight: bold;
      padding: 1rem 2rem;
      margin-right: 1rem;

      &:disabled{
        cursor: not-allowed;
        background: #6c757d;
      }
    }

    @media (max-width: 768px){
      flex-direction: column;

      input{
        padding: 0.5rem;
        margin-right: 0;
        margin-bottom: 0.5rem;
        margin-left: 0;
      }

      button{
        margin-right: 0;
        margin-top: 2rem;
      }
    }
  }

  .messageVerify{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    span, label {
      text-align: center;
      padding: 1rem;
    }

    svg.spin{
      animation: spin 2s infinite;
      color: #0D7DC1;
    }

    @keyframes spin {
      from{
        transform: rotate(0deg);
        transition: 1s
      }
      to{
        transform: rotate(360deg);
      }
    }

    .unavailableContact{
      padding: 1rem;
      display: flex;
      flex-direction: column;
      background: rgba(191, 0, 11, 0.3);
      color: rgb(191, 0, 11);
      border-radius: 5px;
    }

    .availableContact{
      padding: 1rem;
      display: flex;
      flex-direction: column;
      background: rgba(50, 200, 158, 0.3);
      color: rgb(50, 200, 158);
      border-radius: 5px;
    }
  }
`;

export const SearchDate = styled.div`
  .filterDate{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem 0.2rem 0 0.2rem;
    > span{
      .DatePicker__input{
        height: 2.5rem;
        border: 1px solid #e0dfda;
        border-radius: 5px;
        outline: none;
        min-width: 20rem;
        cursor: pointer;
      }
      .DatePicker__calendarContainer{
        border-radius: 5px;     
        border: 1px solid #e0dfda;
        box-shadow: 5px 5px 10px rgba(100,100,100,0.2);
      }
    }
  }

  @media (max-width: 768px){
    display: flex;
    width: 100%;
    justify-content: center;
    .filterDate{
      > span{
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }
  }
`;